@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');


/* تعيين الخط لجميع النصوص */
body, h2, p {
  font-family: 'Cairo', sans-serif;
}
.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; /* ترتيب العناصر بجانب بعض على الشاشات الكبيرة */
  gap: 2rem; /* المسافة بين العناصر */
  flex-wrap: wrap; /* السماح للعناصر بالتنقل إلى صفوف جديدة على الشاشات الصغيرة */
  text-align: center;
}

.hero-image {
  width: 100%;
  max-width: 400px; /* تحديد الحجم الأقصى للصورة */
  border-radius: 10px;
}

.hero-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column; /* تغيير الترتيب إلى عمودي على الشاشات الصغيرة */
    text-align: center;
  }

  .hero-image {
    width: 80%; /* تقليل حجم الصورة على الشاشات الصغيرة */
  }
}

@keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50px); /* الحركة للأعلى */
    }
    100% {
      transform: translateY(0); /* العودة للأسفل */
    }
}

.hero-image {
    animation: moveUpDown 3s ease-in-out infinite;
}

.custom-radius {
    border-radius: 130px; /* قيمة محددة للانحناء */
}
/* في ملف CSS الخاص بك، يمكن إضافة قيمة مخصصة */


/* تأثير الضوء المتحرك */
/* تأثير الضوء المتقلب */
@keyframes ledEffect {
  0% {
    text-shadow: 0 0 1px rgba(255, 255, 255, 1), 0 0 20px rgba(255, 255, 255, 0.8), 0 0 30px rgba(255, 255, 255, 0.5);
  }
  25% {
    text-shadow: 0 0 9px rgba(255, 255, 255, 1), 0 0 40px rgba(255, 255, 255, 0.9), 0 0 50px rgba(255, 255, 255, 0.7);
  }
  50% {
    text-shadow: 0 0 2px rgba(255, 255, 255, 1), 0 0 30px rgba(255, 255, 255, 0.8), 0 0 45px rgba(255, 255, 255, 0.6);
  }
  75% {
    text-shadow: 0 0 5px rgba(255, 255, 255, 1), 0 0 30px rgba(255, 255, 255, 0.7), 0 0 50px rgba(255, 255, 255, 0.5);
  }
  100% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 40px rgba(255, 255, 255, 1), 0 0 60px rgba(255, 255, 255, 0.9);
  }
}

/* إضافة التأثير على النص */
.led-light-effect {
  animation: ledEffect 1s infinite alternate;
}

/* تعديل الأساسيات للصورة */
.relative {
  position: relative;
}





/* حركة النجوم */
@keyframes moveStars {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 0.8;
  }
  100% {
    transform: translateX(1000px) translateY(1000px); /* تتحرك النجوم عبر الشاشة */
    opacity: 0; /* تختفي عند الوصول */
  }
}

/* النجوم المتوهجة */
.stars-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1; /* النجوم تحت المحتوى */
}

.star {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  animation: moveStars linear infinite;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.8); /* إضافة التوهج حول النجوم */
  opacity: 0.8;
}

/* تعريف النجوم المتوهجة */
.star1 {
  width: 8px;
  height: 8px;
  top: 30%;
  left: 10%;
  animation-duration: 15s;
  animation-delay: 2s;
  box-shadow: 0 0 20px rgba(255, 255, 255, 1); /* زيادة التوهج */
}

.star2 {
  width: 6px;
  height: 6px;
  top: 40%;
  left: 20%;
  animation-duration: 12s;
  animation-delay: 4s;
  box-shadow: 0 0 18px rgba(255, 255, 255, 0.9); /* زيادة التوهج */
}

.star3 {
  width: 10px;
  height: 10px;
  top: 50%;
  left: 30%;
  animation-duration: 18s;
  animation-delay: 0s;
  box-shadow: 0 0 25px rgba(255, 255, 255, 1); /* زيادة التوهج */
}

.star4 {
  width: 7px;
  height: 7px;
  top: 60%;
  left: 50%;
  animation-duration: 14s;
  animation-delay: 5s;
  box-shadow: 0 0 22px rgba(255, 255, 255, 0.8); /* زيادة التوهج */
}

.star5 {
  width: 5px;
  height: 5px;
  top: 20%;
  left: 70%;
  animation-duration: 10s;
  animation-delay: 3s;
  box-shadow: 0 0 18px rgba(255, 255, 255, 0.7); /* زيادة التوهج */
}

/* حركة الشهب المتوهجة */
@keyframes moveMeteor {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100vw) translateY(50px);
    opacity: 0;
  }
}

/* الشهب المتوهجة */
.meteor-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.meteor {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  animation: moveMeteor linear infinite;
  box-shadow: 0 0 20px rgba(255, 255, 255, 1); /* إضافة توهج للشهب */
}

/* أنواع الشهب المتوهجة */
.meteor1 {
  top: 10%;
  left: -50px;
  animation-duration: 18s;
  animation-delay: 2s;
}

.meteor2 {
  top: 20%;
  left: -80px;
  animation-duration: 20s;
  animation-delay: 3s;
}

.meteor3 {
  top: 40%;
  left: -100px;
  animation-duration: 22s;
  animation-delay: 4s;
}